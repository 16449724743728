<!-- 解决方案  数字可视化 -->
<template>
    <div class="solution2">
        <header class="header">
            <div class="header-info">
                <h2>数据可视化解决方案</h2>
                <p>
                    大数据时代，数据的价值亟需被挖掘利用起来，真正助力企业的发展。数据可视化与自助分析，以极具视觉冲击力的方式，帮您轻松理解数据，让真正懂业务的人完成分析！
                </p>
                <div class="info-btn">
									<span><a href="#applyContact">预约试用</a></span>
									<span @click="qrCode">联系我们</span>
                </div>
            </div>
            <div class="header-img">
                <img v-lazy="require('../../assets/solutions/pic1@3x.png')" alt="" />
            </div>
        </header>
        <!-- 功能跳转开始 -->
        <div class="feature-list">
            <ul>
                <li v-for="(item, index) in featureList" :key="index" @click="featureListClick(index)">
                    <a :href="item.id" :class="item.isActive ? 'isActive' : ''">{{ item.name }}</a>
                </li>
            </ul>
        </div>
        <!-- 功能跳转结束 -->
        <!-- content -->
        <div class="content">
            <section class="section1">
                <a name="section1" style="position: relative; top: -4.5rem"></a>
                <h2>经典案例</h2>
                <div class="video-container">
                    <div class="box" v-for="(item, index) in section1List" :key="index">
                        <!-- <video
                            class="video"
                            autoplay="autoplay"
                            loop="loop"
                            preload="auto"
                            webkit-playsinline
                            playsinline
                            x5-video-player-type="h5"
                            object-fit="fill"
                        >
                            <source :src="item.url" type="video/mp4" />
                        </video> -->
                        <img :class="'video'+item.box" :src="item.url">
                        <h4>{{ item.title }}</h4>
                    </div>
                </div>
            </section>
            <!-- 预约试用 -->
            <div class="more-container" style="marginTop: 2rem">
                <div class="more">
                    <div class="info">
                        <h4>如需了解更多功能，请预约线下现场演示或直接联系我们</h4>
                        <p>可根据具体场景，具体需求个性化定制功能</p>
                    </div>
                    <div class="info-btn">
                        <!-- <span>预约试用</span>
                        <span>联系我们</span> -->
                        <span><a style="color: #1976e1" href="#applyContact">预约试用</a></span>
                        <span @click="qrCode()">联系我们</span>
                    </div>
                </div>
            </div>
            <section class="section2">
                <a name="section2" style="position: relative; top: -4.5rem"></a>
                <h2>方案架构</h2>
                <p>
                    助力各级人社部门构建基于信用评价、风险分析的多级联动、分级分类，探索人社大数据与社会数据统一汇聚、分析的“互联网+监管”体系。建设人社一体化“互联网+监管”平台，升级内部业务的全覆盖监管、监控能力，实现监管数据可共享、可分析、可预警。
                </p>
                <div class="container"></div>
            </section>
            <!-- 方案优势 -->
            <section class="section3">
                <a name="section3" style="position: relative; top: -4.5rem"></a>
                <h2>方案优势</h2>
                <div class="container">
                    <div class="list" v-for="(item, index) in list3" :key="index">
                        <img v-lazy="item.img" alt="" />
                        <div class="info">
                            <h4>{{ item.title }}</h4>
                            <span></span>
                            <p>{{ item.details }}</p>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 更多解决方案 -->
            <section class="section4">
                <a name="section4" style="position: relative; top: -4.5rem"></a>
                <h2>更多解决方案</h2>
                <div class="container">
                    <div class="list" v-for="(item, index) in list5" :key="index" @click="toSolutionPage(item.path)">
                        <h4>{{ item.title }}</h4>
                        <p>{{ item.details }}</p>
                        <span>了解详情</span>
                    </div>
                </div>
            </section>
        </div>
        <!-- 二维码 -->
        <contactUs ref="contactUs"></contactUs>
        <!-- 移动端二维码 -->
				<apply ref="apply"></apply>
    </div>
</template>

<script>
import apply from "@/components/apply/apply";
import contactUs from "@/components/contactUs/contactUs";
import { myMixins } from "@/utils/mixin/mixin.js";
export default {
    mixins: [myMixins],
    components: { apply, contactUs },
    data() {
        return {
            id: 1, //标识 区分哪一个解决方案，来显示动态显示更多解决方案
            featureList: [
                // { name: "经典案例", isActive: true, id: "#section1" },
                { name: "方案架构", isActive: false, id: "#section2" },
                { name: "方案优势", isActive: false, id: "#section3" },
                // { name: "行业痛点", isActive: false, id: "#section4" },
                { name: "更多解决方案", isActive: false, id: "#section4" },
            ], //功能列表跳转
            section1List: [
                {
                    url: "https://images.pingzhengyun.cn/site-screen/1.gif",
                    title: "全域化管理平台",
										box: 1,
                },
                {
                    url: "https://images.pingzhengyun.cn/site-screen/2.gif",
                    title: "实时运营监控中心",
										box: 1,
                },
                {
                    url: "https://images.pingzhengyun.cn/site-screen/3.gif",
                    title: "极经济足球图",
										box: 1,
                },
                {
                    url: "https://images.pingzhengyun.cn/site-screen/4.gif",
                    title: "城市联动数据",
										box: 2,
                },
                {
                    url: "https://images.pingzhengyun.cn/site-screen/5.gif",
                    title: "数字化管控平台",
										box: 1,
                },
                {
                    url: "https://images.pingzhengyun.cn/site-screen/6.gif",
                    title: "智慧调度中心",
										box: 2,
                },
                {
                    url: "https://images.pingzhengyun.cn/VKCEYUGU-f5fff73a-aef5-45f7-94db-574de3a259d0/2c702e28-a117-480b-adbf-da89ed1c1118.gif",
                    title: "长三角战略版图",
										box: 1,
                },
            ],
            list3: [
                {
                    title: "管理能力提升",
                    img: require("../../assets/solutions/s1.png"),
                    details:
                        "我们拥有专业的数据行为分析团队，可以更加快速精准的把握到企业的每一个痛点、难点，以便及时寻找到更合适的解决方案。",
                },
                {
                    title: "运营效率提升",
                    img: require("../../assets/solutions/s2.png"),
                    details:
                        "全面分析各地人社部门基础数据与业务数据，助力各地人社部门跨业务领域的数据共享，实现精准服务、精准施策。",
                },
                {
                    title: "实现数据交互可视化",
                    img: require("../../assets/solutions/s3.png"),
                    details: "打破数据孤岛，实现全流程实时监控预警，事件分析及预演帮助产业管理高效协同。",
                },
                {
                    title: "多元服务助力产品创新",
                    img: require("../../assets/solutions/s4.png"),
                    details: "通过视频监控设备，对企业及园区、景区、社区、楼宇等实时监测，实现全局可视化管理。",
                },
            ], //方案优势
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        // this.distinguish();
    },
    methods: {
        // 功能列表跳转
        featureListClick(e) {
            this.featureList.map((item, index) => {
                item.isActive = e === index ? true : false;
            });
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "solution_PC2.scss";
}
@media screen and (max-width: 750px) {
    @import "solution_mobile2.scss";
}
</style>
